import {createRouter,  createWebHistory} from "vue-router";
//路由定义

const routes = [
    {path: "/",  component: () => import('../page/AuthPage.vue')},
    {path: '/auth', component: () => import('../page/AuthPage.vue')},
    {path: '/redirect', component: () => import('../page/RedirectPage.vue')},
    {path: '/openid', component:() => import("../page/OpenidPage.vue")},
    {path: "/coupon", component: () => import('../page/CouponPage.vue')},
    {path: '/error', component: () => import('../page/ErrorPage.vue')},
    {path: "/getOpenid", component: () => import('../page/GetOpenid.vue')},
    {path: "/remove", component: () => import('../page/RemovePage.vue')},
    {path: '/:catchAll(.*)', component: () => import('../page/NotFound.vue')}, // This will handle any path that does not match the above rules
]

// 创建路由
const router = createRouter({
    history: createWebHistory("/"),
    routes,
});

export default router;